/* eslint-disable no-plusplus */
/* eslint-disable import/no-duplicates */
import { Locale as DateFNSLocale } from 'date-fns';
import { ptBR, enUS } from 'date-fns/locale';

import capitalizeFirstLetter from './capitalizeFirstLetter';

const locales: { [key: string]: DateFNSLocale } = {
  'pt-BR': ptBR,
  'en-US': enUS,
};

type Locale = 'pt-BR' | 'en-US';

const defaultLocale: Locale = 'pt-BR';

type Args = {
  locale?: Locale;
  [key: string]: any;
};

export function getMonths(args?: Args): string[] {
  const locale = args?.locale ?? defaultLocale;
  const currentLocale = locales[locale];
  const months: string[] = [];
  let month = '';
  for (let i = 0; i < 12; i++) {
    month = capitalizeFirstLetter(currentLocale.localize?.month(i, args));
    months.push(month);
  }
  return months;
}

export function getDaysOfWeek(args?: Args): string[] {
  const locale = args?.locale ?? defaultLocale;
  const currentLocale = locales[locale];
  const daysOfWeek: string[] = [];
  let dayOfWeek = '';
  for (let i = 0; i < 7; i++) {
    dayOfWeek = capitalizeFirstLetter(currentLocale.localize?.day(i, args));
    daysOfWeek.push(dayOfWeek);
  }
  return daysOfWeek;
}
